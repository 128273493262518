import { ethers } from 'ethers';

const tokens: any = {
    dax: {
        address: '0xDE373AE127A11E756A9D1cc7743816928B239283',
        symbol: 'DAX',
        decimals: 18,
        image: "https://dax.best/dax.best_avatar.png"
    },
    wflr: {
        address: '0x1d80c49bbbcd1c0911346656b529df9e5c2f783d',
        symbol: 'WFLR',
        decimals: 18,
        image: "https://dax.best/wflr_token_icon.svg"
    }
};

export const addTokenToWallet = async (type: string) => {
    if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
    }

    const provider = new ethers.BrowserProvider(window.ethereum);
    const token = tokens[type];

    if (!token) {
        throw new Error('Token type not supported');
    }

    await provider.send('wallet_watchAsset', {
        type: 'ERC20',
        options: {
            address: token.address,
            symbol: token.symbol,
            decimals: token.decimals,
            image: token.image,
        },
    });

    console.log(`${token.symbol} Token hinzugefügt`);
};